.card { transition: all .2s ease-in-out;
cursor: pointer; 
border-radius: 15px !important;
border: none !important;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}

.card:hover{
    transform: scale(1.03);
}

.card img{
    border-radius: 15px 15px 0px 0px !important;
}